import Caption from 'components/Luxkit/Typography/Caption'
import getLuxLoyaltyTierDisplayName from 'luxLoyalty/selectors/getLuxLoyaltyTierDisplayName'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'

const Badge = styled(Caption)`
  padding: ${rem(4)} ${rem(8)};
  border-radius: ${props => props.theme.borderRadius.S};

  &.tier-bronze {
    background-color: ${props => props.theme.palette.product.luxLoyalty.bronze.background};
    color: ${props => props.theme.palette.product.luxLoyalty.bronze.contrast};
  }

  &.tier-silver {
    background-color: ${props => props.theme.palette.product.luxLoyalty.silver.background};
    color: ${props => props.theme.palette.product.luxLoyalty.silver.contrast};
  }

  &.tier-gold {
    background-color: ${props => props.theme.palette.product.luxLoyalty.gold.background};
    color: ${props => props.theme.palette.product.luxLoyalty.gold.contrast};
  }

  &.tier-platinum {
    background-color: ${props => props.theme.palette.product.luxLoyalty.platinum.background};
    color: ${props => props.theme.palette.product.luxLoyalty.platinum.contrast};
  }
`

interface Props {
  tier: App.LuxLoyaltyTier
}

function LuxLoyaltyTierBadge({
  tier,
}: Props) {
  return <Badge className={`tier-${tier}`} variant="medium" format="uppercase" colour="neutral-eight">
    {getLuxLoyaltyTierDisplayName(tier)}
  </Badge>
}

export default LuxLoyaltyTierBadge
